<template>
    <div>
      <Feed :style="'margin-top: 1%;'"/>
    </div>
</template>

<script>
import Feed from '@/components/FeedSettings.vue';

export default{
    components: {
        Feed
    },
}
</script>

<style>
.container {
  text-align: center;
}
.validationAdress {
  text-align: center;
  margin: 0px 600px 0px 600px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope='row'] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope='row'] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

.shipping {
  margin: 0px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.shipping:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.autoPrice {
  margin: 0px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.autoPrice:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.table {
  text-align: center;
}
</style>
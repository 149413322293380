<template>
  <div>
    <!-- Cabeçalho e Secção de Instruções -->
    <header style="text-align: center; margin: 20px 0">
      <h1 style="font-size: 2.5em; color: #2c3e50">Kuanto Kusta</h1>
      <!-- Secção de Instruções -->
      <section
        style="
          max-width: 800px;
          margin: 0 auto;
          text-align: left;
          background: #f7f7f7;
          padding: 15px;
          border-radius: 4px;
        "
      >
        <h3 style="color: #34495e">Instruções</h3>
        <p style="font-size: 1.1em; line-height: 1.5">
          Nesta página pode gerir todas as configurações e dados relativos ao
          feed <strong>Kuanto Kusta</strong>. Para uma melhor compreensão, as
          instruções estão organizadas por área:
        </p>
        <h4 style="margin-top: 15px; color: #2c3e50">Feed Valores</h4>
        <ul style="margin-left: 20px; font-size: 1em">
          <li>
            <strong>Fee:</strong> Insira o valor do fee que será aplicado no
            feed.
          </li>
          <li><strong>País:</strong> O país associado ao fee.</li>
        </ul>
        <h4 style="margin-top: 15px; color: #2c3e50">Definições</h4>
        <ul style="margin-left: 20px; font-size: 1em">
          <li>
            <strong>Farmas:</strong> Insira o valor máximo de stock a utilizar
            para calcular o stock total do feed.
          </li>
          <li>
            <strong>OCP:</strong> Insira o valor máximo de stock da OCP, o qual
            será utilizado nos cálculos do feed.
          </li>
          <li>
            <strong>Nova Engel:</strong> Insira o valor que, quando dividido
            pelo stock atual da Nova Engel, fornece o valor ajustado para o
            feed.
          </li>
          <li>
            <strong>Apenas Site:</strong> Se ativado, serão considerados apenas
            os produtos disponíveis no site para a criação do feed.
          </li>
        </ul>
        <h4 style="margin-top: 15px; color: #2c3e50">Intervalos</h4>
        <ul style="margin-left: 20px; font-size: 1em">
          <li>
            <strong>Preço Custo Mínimo (price_ini):</strong> Valor mínimo do
            intervalo.
          </li>
          <li>
            <strong>Preço Custo Máximo (price_final):</strong> Valor máximo do
            intervalo.
          </li>
          <li><strong>Margem (margin):</strong> Valor fixo em euros.</li>
          <li>
            <strong>Multiplo (mult):</strong> Fator multiplicador para cálculos
            adicionais.
          </li>
          <li>
            <strong>Soma (soma):</strong> Valor a somar ao resultado final.
          </li>
        </ul>
        <h4 style="margin-top: 15px; color: #2c3e50">Preço dos Envios</h4>
        <ul style="margin-left: 20px; font-size: 1em">
          <li>
            Defina as faixas de peso (mínimo e máximo) e o respetivo preço de
            envio.
          </li>
        </ul>
        <h4 style="margin-top: 15px; color: #2c3e50">Produtos sem Feed</h4>
        <ul style="margin-left: 20px; font-size: 1em">
          <li>
            Utilize esta área para gerir os produtos que ainda não foram
            incluídos no feed, identificando-os pelo SKU.
          </li>
        </ul>
      </section>
    </header>

    <!-- Secção: Feed Valores -->
    <section style="margin-bottom: 40px">
      <h2 style="text-align: center">Feed Valores</h2>
      <table style="width: 60%; margin: auto">
        <thead>
          <tr>
            <th>Fee</th>
            <th>País</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="value in values" :key="value.id">
            <td style="width: 10%">
              <input
                type="text"
                class="form-control"
                v-model="value.fee"
                @change="updateValues(value)"
              />
            </td>
            <td style="width: 10%">{{ value.country }}</td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Secção: Definições -->
    <section style="margin-bottom: 40px">
      <h2 style="text-align: center">Definições</h2>
      <table style="width: 60%; margin: auto">
        <thead>
          <tr>
            <th>Farmas</th>
            <th>OCP</th>
            <th>Nova Engel</th>
            <th>Apenas Site</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="setting in settings" :key="setting.id">
            <td>
              <input
                type="number"
                class="form-control"
                v-model="setting.farmas_stock"
                @change="updateSetting(setting)"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="setting.ocp_stock"
                @change="updateSetting(setting)"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="setting.nova_engel"
                @change="updateSetting(setting)"
              />
            </td>
            <td>
              <b-form-checkbox
                :id="'only_site_' + setting.id"
                v-model="setting.only_site"
                switch
                @change="updateSetting(setting)"
              ></b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Secção: Intervalos -->
    <section style="margin-bottom: 40px">
      <h2 style="text-align: center">Intervalos do KK</h2>
      <div style="text-align: center; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_intervalo')">
          Adicionar Intervalo
        </button>
      </div>
      <table style="width: 90%; margin: auto">
        <thead>
          <tr>
            <th>Preço Inicial</th>
            <th>Preço Final</th>
            <th>Margem</th>
            <th>Multiplo</th>
            <th>Soma</th>
            <th>Remover</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="interval in intervals" :key="interval.id">
            <td>
              <input
                type="number"
                class="form-control"
                v-model="interval.price_ini"
                @change="editInterval(interval)"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="interval.price_final"
                @change="editInterval(interval)"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="interval.margin"
                @change="editInterval(interval)"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="interval.mult"
                @change="editInterval(interval)"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="interval.soma"
                @change="editInterval(interval)"
              />
            </td>
            <td>
              <a @click="deleteInterval(interval.id)" style="cursor: pointer"
                >❌</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Secção: Preço dos Envios -->
    <section style="margin-bottom: 40px">
      <h2 style="text-align: center">Preço dos Envios</h2>
      <div style="text-align: center; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_shipping')">
          Adicionar Shipping
        </button>
      </div>
      <table style="width: 80%; margin: auto">
        <thead>
          <tr>
            <th>Feed</th>
            <th>Mínimo</th>
            <th>Máximo</th>
            <th>Envio</th>
            <th>Remover</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in shippings" :key="s.id">
            <td>{{ s.feed }}</td>
            <td>{{ s.minimo }}</td>
            <td>{{ s.maximo }}</td>
            <td>{{ s.shipping }}</td>
            <td>
              <a @click="deleteShipping(s.id)" style="cursor: pointer">❌</a>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Secção: Produtos sem Feed -->
    <section style="margin-bottom: 40px">
      <h2 style="text-align: center">Produtos sem Feed</h2>
      <div style="text-align: center; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_product')">
          Adicionar Produto
        </button>
      </div>
      <div style="width: 80%; margin: auto">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">SKU:</span>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="skuProduct"
            @change="filterNoProducts"
          />
        </div>
      </div>
      <table style="width: 80%; margin: auto">
        <thead>
          <tr>
            <th>ID</th>
            <th>SKU</th>
            <th>Envio</th>
            <th>Feed</th>
            <th>Remover</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="np in noProducts" :key="np.id">
            <td>{{ np.id }}</td>
            <td>
              <b-link @click="routeToProduct(np.id_product)">{{
                np.sku
              }}</b-link>
            </td>
            <td>{{ np.name_product }}</td>
            <td>{{ np.feed }}</td>
            <td>
              <a @click="deleteProduct(np.id)" style="cursor: pointer">❌</a>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Modais para Adicionar Registos -->

    <!-- Modal: Adicionar Intervalo -->
    <b-modal
      ref="modal_intervalo"
      id="modal_intervalo"
      title="Adicionar Intervalo (KK)"
      hide-footer
    >
      <form @submit.prevent="addInterval">
        <p><strong>Feed:</strong> Kuanto Kusta</p>
        <!-- Campo: Preço Custo Mínimo -->
        <div class="form-group">
          <label>Preço Custo Mínimo (price_ini):</label>
          <input
            type="number"
            class="form-control"
            v-model="price_ini"
            step="0.01"
            required
          />
          <small class="form-text text-muted">
            Valor mínimo do intervalo.
          </small>
        </div>
        <!-- Campo: Preço Custo Máximo -->
        <div class="form-group">
          <label>Preço Custo Máximo (price_final):</label>
          <input
            type="number"
            class="form-control"
            v-model="price_final"
            step="0.01"
            required
          />
          <small class="form-text text-muted">
            Valor máximo do intervalo.
          </small>
        </div>
        <!-- Campo: Margem -->
        <div class="form-group">
          <label>Margem (margin):</label>
          <input
            type="number"
            class="form-control"
            v-model="margin"
            step="0.01"
            required
          />
          <small class="form-text text-muted">
            Percentagem ou valor fixo a aplicar.
          </small>
        </div>
        <!-- Campo: Multiplo -->
        <div class="form-group">
          <label>Multiplo (mult):</label>
          <input
            type="number"
            class="form-control"
            v-model="mult"
            step="0.01"
            required
          />
          <small class="form-text text-muted">
            Fator multiplicador para cálculos adicionais.
          </small>
        </div>
        <!-- Campo: Soma -->
        <div class="form-group">
          <label>Soma (soma):</label>
          <input
            type="number"
            class="form-control"
            v-model="soma"
            step="0.01"
            required
          />
          <small class="form-text text-muted">
            Valor a somar ao resultado final.
          </small>
        </div>
        <div style="text-align: center">
          <button type="submit" class="btn btn-primary">
            Adicionar Intervalo
          </button>
        </div>
      </form>
    </b-modal>

    <!-- Modal: Adicionar Shipping -->
    <b-modal
      ref="modal_shipping"
      id="modal_shipping"
      title="Adicionar Shipping"
      hide-footer
    >
      <form @submit.prevent="addShipping">
        <p><strong>Feed:</strong> Kuanto Kusta</p>
        <div class="form-group">
          <label>Peso Mínimo:</label>
          <input
            type="number"
            class="form-control"
            v-model="minimo"
            step="0.01"
            required
          />
        </div>
        <div class="form-group">
          <label>Peso Máximo:</label>
          <input
            type="number"
            class="form-control"
            v-model="maximo"
            step="0.01"
            required
          />
        </div>
        <div class="form-group">
          <label>Preço Envio:</label>
          <input
            type="number"
            class="form-control"
            v-model="shipping"
            step="0.01"
            required
          />
        </div>
        <div style="text-align: center">
          <button type="submit" class="btn btn-primary">
            Adicionar Shipping
          </button>
        </div>
      </form>
    </b-modal>

    <!-- Modal: Adicionar Margem -->
    <!-- Este modal foi mantido para que, se necessário, se possa adicionar margens -->
    <b-modal
      ref="modal_margem"
      id="modal_margem"
      title="Adicionar Margem"
      hide-footer
    >
      <form @submit.prevent="addMargem">
        <p><strong>Feed:</strong> Kuanto Kusta</p>
        <div class="form-group">
          <label>Preço Custo Mínimo:</label>
          <input
            type="number"
            class="form-control"
            v-model="minimo"
            step="0.01"
            required
          />
        </div>
        <div class="form-group">
          <label>Preço Custo Máximo:</label>
          <input
            type="number"
            class="form-control"
            v-model="maximo"
            step="0.01"
            required
          />
        </div>
        <div class="form-group">
          <label>Margem:</label>
          <input
            type="number"
            class="form-control"
            v-model="margem"
            step="0.01"
            required
          />
        </div>
        <div style="text-align: center">
          <button type="submit" class="btn btn-primary">
            Adicionar Margem
          </button>
        </div>
      </form>
    </b-modal>

    <!-- Modal: Adicionar Produto -->
    <b-modal
      ref="modal_product"
      id="modal_product"
      title="Adicionar Produto"
      hide-footer
    >
      <div class="form-group">
        <label for="sku">SKU:</label>
        <input
          type="text"
          class="form-control"
          v-model="skuSearch"
          @change="getProductInfo"
        />
      </div>
      <p><strong>Feed:</strong> Kuanto Kusta</p>
      <div v-if="product && product.length">
        <img
          :src="product[0].img"
          alt="Imagem do Produto"
          style="width: 300px; height: 300px; display: block; margin: auto"
        />
        <h6 style="text-align: center">{{ product[0].name }}</h6>
        <p style="text-align: center">
          <strong>SKU:</strong> {{ product[0].sku }}
        </p>
        <p style="text-align: center">
          <strong>EAN:</strong> {{ product[0].ean }}
        </p>
        <p style="text-align: center">
          <strong>Stock:</strong> {{ product[0].stock }}
        </p>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <button class="btn btn-primary" @click="addNoProduct">
          Adicionar Produto
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'KuantokustaPage',
  data() {
    return {
      // Dados para Definições
      settings: [],
      // Dados para Intervalos
      intervals: [],
      // Campos para novo intervalo (API: price_ini, price_final, margin, mult, soma)
      price_ini: '',
      price_final: '',
      margin: '',
      mult: '',
      soma: '',
      // Dados para Fee (Feed Valores)
      values: [],
      // Dados para Shipping
      shippings: [],
      shipping: '',
      // Dados para Produtos sem Feed
      noProducts: [],
      skuProduct: '',
      // Dados para o Modal de Produto
      skuSearch: '',
      product: [],
    };
  },
  computed: {
    ...mapGetters([
      'getAllFeedSettings',
      'getKKIntervals',
      'getAllFeedShipping',
      'getAllFeedValues',
      'getProduct',
      'getNoProductsFeed',
    ]),
  },
  methods: {
    // --- Definições ---
    async fetchSettings() {
      await this.$store.dispatch('getFeedSettings');
      this.settings = this.getAllFeedSettings.filter(
        (s) => s.feed === 'kuanto kusta'
      );
    },
    async updateSetting(setting) {
      try {
        await this.$store.dispatch('updateFeedSettings', {
          feed: setting.feed,
          ocp_stock: setting.ocp_stock,
          nova_engel: setting.nova_engel,
          farmas_stock: setting.farmas_stock,
          only_site: setting.only_site,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Definição atualizada com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
      } catch (error) {
        alert(error);
      }
    },

    // --- Feed Valores (Fee) ---
    async fetchFeeValues() {
      await this.$store.dispatch('getFeedValues');
      this.values = this.getAllFeedValues.filter(
        (s) => s.feed === 'kuanto kusta'
      );
    },
    async updateValues(value) {
      try {
        await this.$store.dispatch('updateFeedValues', {
          feed: value.id,
          soma: value.soma,
          multiplo: value.multiplo,
          margem: value.margem,
          fee: value.fee,
          shipping: value.shipping,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Fee atualizado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
      } catch (err) {
        alert(err);
      }
    },

    // --- Intervalos ---
    async fetchIntervals() {
      await this.$store.dispatch('getKKIntervals');
      this.intervals = this.getKKIntervals.intervals;
      console.log('INTERVALOS:', this.intervals);
    },
    async addInterval() {
      try {
        await this.$store.dispatch('addKKIntervals', {
          price_ini: this.price_ini,
          price_final: this.price_final,
          margin: this.margin,
          mult: this.mult,
          soma: this.soma,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Intervalo adicionado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs.modal_intervalo.hide();
        // Reseta os campos do modal
        this.price_ini = '';
        this.price_final = '';
        this.margin = '';
        this.mult = '';
        this.soma = '';
        await this.fetchIntervals();
      } catch (error) {
        alert(error);
      }
    },
    async editInterval(interval) {
      try {
        await this.$store.dispatch('updateKKIntervals', {
          id: interval.id,
          price_ini: interval.price_ini,
          price_final: interval.price_final,
          margin: interval.margin,
          mult: interval.mult,
          soma: interval.soma,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Intervalo atualizado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.fetchIntervals();
      } catch (error) {
        alert(error);
      }
    },
    async deleteInterval(id) {
      try {
        await this.$store.dispatch('deleteKKIntervals', { id });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Intervalo apagado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.fetchIntervals();
      } catch (error) {
        alert(error);
      }
    },

    // --- Shipping ---
    async fetchShippings() {
      await this.$store.dispatch('getShippingFeed');
      this.shippings = this.getAllFeedShipping.filter(
        (s) => s.feed === 'kuanto kusta'
      );
    },
    async addShipping() {
      try {
        await this.$store.dispatch('addShippingFeed', {
          feed: 'kuanto kusta',
          shipping: this.shipping,
          maximo: this.maximo,
          minimo: this.minimo,
          country: 'all',
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Shipping adicionado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs.modal_shipping.hide();
        this.shipping = '';
        this.maximo = '';
        this.minimo = '';
        await this.fetchShippings();
      } catch (error) {
        alert(error);
      }
    },
    async deleteShipping(id) {
      try {
        await this.$store.dispatch('deleteFeedShipping', { id });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Shipping apagado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.fetchShippings();
      } catch (error) {
        alert(error);
      }
    },

    // --- Produtos sem Feed ---
    async fetchNoProducts() {
      await this.$store.dispatch('noProductFeed');
      this.noProducts = this.getNoProductsFeed.filter(
        (n) => n.feed === 'kuanto kusta'
      );
    },
    async filterNoProducts() {
      try {
        await this.$store.dispatch('filterNoProductsFeed', {
          sku: this.skuProduct,
          feed: 'kuanto kusta',
        });
        this.noProducts = this.getNoProductsFeed.filter(
          (n) => n.feed === 'kuanto kusta'
        );
      } catch (error) {
        alert(error);
      }
    },
    async getProductInfo() {
      try {
        await this.$store.dispatch('fetchProductsSKU', { sku: this.skuSearch });
        this.product = this.getProduct;
      } catch (error) {
        console.error(error);
      }
    },
    async addNoProduct() {
      try {
        await this.$store.dispatch('addNoProductFeed', {
          sku: this.product[0].sku,
          feed: 'kuanto kusta',
          nameProduct: this.product[0].name,
          id_product: this.product[0].id_backoffice,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Produto adicionado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs.modal_product.hide();
        this.product = [];
        this.skuSearch = '';
        await this.fetchNoProducts();
      } catch (error) {
        alert(error);
      }
    },
    routeToProduct(productId) {
      window.open(
        `https://${window.location.host}/product/${productId}`,
        '_blank'
      );
    },
    deleteProduct(id) {
      try {
        this.$store.dispatch('deleteNoProductFeed', { id });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Produto apagado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.fetchNoProducts();
      } catch (error) {
        alert(error);
      }
    },

    // --- Modal ---
    openModal(refName) {
      this.$refs[refName].show();
    },

    // --- Carrega todos os dados ---
    async loadAllData() {
      await this.fetchSettings();
      await this.fetchIntervals();
      await this.fetchFeeValues();
      await this.fetchShippings();
      await this.fetchNoProducts();
    },
  },
  async created() {
    await this.loadAllData();
  },
};
</script>

<style scoped>
table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border: 1px solid #ccc;
  padding: 8px;
}
a {
  text-decoration: underline;
}
header section ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
